// $("body").addClass("viewing-"+location.pathname.substring(1).split('.').slice(0, -1).join('.'));

// var blockUI = function(){
//   if(!document.querySelector('.mask')){
//       var mask = document.createElement('div');
//       var loaderContainer = document.createElement('div');
//       var loader = document.createElement('div');
//       mask.classList.add('mask');
//       loaderContainer.classList.add('loader-container');
//       loader.classList.add('loader');
//       loaderContainer.appendChild(loader);
//       mask.appendChild(loaderContainer)
//       document.querySelector('body').appendChild(mask);
//   }
// }

// var unblockUI = function(){
//   var mask = document.querySelector(".mask");
//   if(mask){
//       document.querySelector('body').removeChild(mask);
//   }
// }

jQuery.extend({
  blockUI: function() {
      if(!document.querySelector('.mask')){
        var mask = document.createElement('div');
        var loaderContainer = document.createElement('div');
        var loader = document.createElement('div');
        mask.classList.add('mask');
        loaderContainer.classList.add('loader-container');
        loader.classList.add('loader');
        loaderContainer.appendChild(loader);
        mask.appendChild(loaderContainer)
        document.querySelector('body').appendChild(mask);
      }
  },
  unblockUI: function() {
    var mask = document.querySelector(".mask");
    if(mask){
        document.querySelector('body').removeChild(mask);
    }
  },
  showToast: function(message, option){
    var toast = document.createElement('div');
    toast.id = "snackbar";
    toast.innerHTML = message;
    document.body.appendChild(toast);
    if(option === "error"){
      toast.classList.add("error");
    }else if(option === "warning"){
      toast.classList.add("warning");
    }else if(option === "success"){
      toast.classList.add("success");
    }else if(option === "info"){
      toast.classList.add("info");
    }
    toast.classList.add("show");
    setTimeout(()=>{ toast.classList.remove("show") }, 5000);
  }
});

$("#show-pass,.show-pass").on("click",function(){
    if ($(".login-pass-field").attr("type") == "password"){
        $(".login-pass-field").attr("type","text");
    }
    else{
        $(".login-pass-field").attr("type","password");
    }
});


// $("#new_pass_repeat").on("keyup",function(){
//     if ($('#new_pass').val() == $('#new_pass_repeat').val()){
//       console.log("matched");
//       $(".not-matched").fadeOut();
//       $(".matched").fadeIn();
//     }
//     else{
//       console.log("Not Matched");
//       $(".matched").fadeOut();
//       $(".not-matched").fadeIn();
//     }
// });


// $(document).ready(function(){
//   $(".search-filter input").on("keyup", function() {
//     var value = $(this).val().toLowerCase();
//     $(".record-table table tr").not(".propt-th").filter(function() {
//       $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
//     });
//   });
// });


// $(document).ready(function () {
//   $(".selectall").click(function () {
//       $(".record-check").prop('checked', $(this).prop('checked'));
//   });
  
//   $(".record-check").change(function(){
//       if (!$(this).prop("checked")){
//           $(".selectall").prop("checked",false);
//       }
//   });
  
// });

// $(".upload-attachment").change(function(){
//   if(e.target.value !== ""){
//     var pdf = $(this).siblings(`img[alt="File"]`);
//     pdf.attr("src","src/images/svg/pdf.svg");
//     pdf.on('click', function(e){

//     })
//   }
// });




$('.admin-sidebar ul li').not(".as-logo").click(function(){
  $(this).parent().children().not(".as-logo").removeClass("active");
  $(this).not(".as-logo").addClass("active");
});

if ((window.matchMedia("(max-width: 667px)").matches) && ($("#swipezone").length > 0)) {

  // Swipe detection
  function swipedetect(el, callback){
    
    var touchsurface = el,
    swipedir,
    startX,
    startY,
    distX,
    distY,
    threshold = 150, //required min distance traveled to be considered swipe
    restraint = 100, // maximum distance allowed at the same time in perpendicular direction
    allowedTime = 300, // maximum time allowed to travel that distance
    elapsedTime,
    startTime,
    handleswipe = callback || function(swipedir){};

    touchsurface.addEventListener('touchstart', function(e){
        var touchobj = e.changedTouches[0];
        swipedir = 'none';
        startX = touchobj.pageX;
        startY = touchobj.pageY;
        startTime = new Date().getTime(); // record time when finger first makes contact with surface
        
    }, false);

    touchsurface.addEventListener('touchmove', function(e){
        // prevent scrolling when inside DIV
    }, false);

    touchsurface.addEventListener('touchend', function(e){
        var touchobj = e.changedTouches[0];
        distX = touchobj.pageX - startX; // get horizontal dist traveled by finger while in contact with surface
        distY = touchobj.pageY - startY; // get vertical dist traveled by finger while in contact with surface
        elapsedTime = new Date().getTime() - startTime; // get time elapsed
        if (elapsedTime <= allowedTime){ // first condition for awipe met
            if (Math.abs(distX) >= threshold && Math.abs(distY) <= restraint){ // 2nd condition for horizontal swipe met
                swipedir = (distX < 0)? 'left' : 'right'; // if dist traveled is negative, it indicates left swipe
            }
            else if (Math.abs(distY) >= threshold && Math.abs(distX) <= restraint){ // 2nd condition for vertical swipe met
                swipedir = (distY < 0)? 'up' : 'down'; // if dist traveled is negative, it indicates up swipe
            }
        }
        handleswipe(swipedir);
        
    }, false);
  }
  // Swipe detection
  
  var swipezone = document.getElementById('swipezone');

  swipedetect(swipezone, function(swipedir){
    
    if( (swipedir == "right") || (swipedir == "top") || (swipedir == "right")){
      $(".admin-sidebar").toggleClass("slide-in");
    }
  });

  var bar = document.getElementById('admin-sidebar');
  swipedetect(bar, function(swipedir){

      if( (swipedir == "left") ){
        $(".admin-sidebar").toggleClass("slide-in");
      }
  });

}

$(window).on("load",function(){
  // $('table').DataTable({
  //   // paging: false,
  //   scrollY: 400,
  //   "dom": 't',
  // });
  $('[data-toggle="datepicker"]').parent("td").css("padding","0.5rem");
  $('[data-toggle="datepicker"]').datepicker({
    format: 'yyyy-mm-dd',
    autoHide: true,
  });
  
});


$(window).bind("load", function() {
  if (window.matchMedia("(min-width: 668px)").matches){
    $(".dataTables_scrollBody").niceScroll({
      cursorcolor:"#637077",
      horizrailenabled:false
    });
  }

  
  function getCheckedLength(selector){
    var countCheckedCheckboxes = selector.filter(':checked').length;
    $('.filter_'+selector[0].name+' .filter-count').text(pad2(countCheckedCheckboxes));
  }
  
  var invoice_checkboxes = $('input[name="invoice_sent"]');
  var payment_checkboxes = $('input[name="payment_received"]');
  var record_checkboxes = $('input[name="records_sent"]');

  invoice_checkboxes.on("change",function(){
    var countCheckedCheckboxes = invoice_checkboxes.filter(':checked').length;
    $('.filter_'+$(this)[0].name+' .filter-count').text(pad2(countCheckedCheckboxes));
  });
  payment_checkboxes.on("change",function(){
    var countCheckedCheckboxes = payment_checkboxes.filter(':checked').length;
    $('.filter_'+$(this)[0].name+' .filter-count').text(pad2(countCheckedCheckboxes));
  });
  record_checkboxes.on("change",function(){
    var countCheckedCheckboxes = record_checkboxes.filter(':checked').length;
    $('.filter_'+$(this)[0].name+' .filter-count').text(pad2(countCheckedCheckboxes));
  });

  if ($(".filter-count").length>0){
    getCheckedLength(invoice_checkboxes);
    getCheckedLength(payment_checkboxes);
    getCheckedLength(record_checkboxes);
  }
});

$(".cf-btn").on("click",function(){
  $(".filter-checks input").prop("checked", false);
  $('.record-table table tr').show();
  $(".filter-checks label").removeClass("checked");
});

$(".print").on("click",function(e){
  e.preventDefault();
  window.print();
});

$(document).ready(function(){

  $(".search-user input").on("keyup", function() {
    var value = $(this).val().toLowerCase();
    $(".user-list-wrapper .user-list-item").filter(function() {
      $(this).toggle($(this).text().toLowerCase().indexOf(value) > -1);
    });
  });

  const fileInput = document.getElementById('report-file');
  if(fileInput){
    const fileLabel =  document.getElementById('file-input-label');
    const form = fileInput.form;
    fileLabel.setAttribute("title", "No document of proof attached");

    form.addEventListener("change", function(e){
      if(!fileInput.value){
        removeAttachment(fileLabel);
      }else{
        //fileLabel.innerText = fileInput.value.match(//);
        let fileName = fileInput.value.match(/[^/\\]+\..+/)[0];
        fileLabel.innerHTML = `<img src="src/images/svg/attach.svg">${fileName}`;
        fileLabel.setAttribute("title", "Document of proof has been attached");
        fileLabel.classList.add("active");
      }
    })

    form.addEventListener("reset", function(e){
        removeAttachment(fileLabel);
    })
  }

  function removeAttachment(label){
    label = label? label: document.getElementById('file-input-label');
    label.innerHTML = `<img src="src/images/svg/attach.svg">Attach proof of request`;
    label.setAttribute("title", "No document of proof attached");
    label.classList.remove("active");
  }

  //TODO
  if(document.getElementById("prd-button")){
    const button = document.getElementById("prd-button");
    button.classList.add("disabled");
    const radioButtons = Array.from(document.querySelectorAll("input[type='radio']"));
    document.addEventListener("change", function(e){
      if(e.target.type==="radio"){
        if(radioButtons.some(radio =>  radio.checked )){
          button.classList.remove("disabled");
        }
        
      }
    })
  }

  if(document.getElementById("pr-save-button")){
    const buttons = document.querySelectorAll(".btn");
    buttons.forEach( button => {
      button.classList.add("disabled");
      button.setAttribute("disabled", "");
    });
    const checkboxes = Array.from(document.querySelectorAll(`input[type="checkbox"]`));
    document.addEventListener("change", function(e){
      if(checkboxes.some( checkbox => checkbox.checked)){
        buttons.forEach( button => {
          button.classList.remove("disabled");
          button.removeAttribute("disabled");
        });
      }else{
        buttons.forEach( button => {
          button.classList.add("disabled");
          button.setAttribute("disabled", "");
        });
      }
    })    
  }
 
  

  if(document.querySelector(".upload-attachment")){
    document.querySelectorAll(`input[type="checkbox"]`).forEach(checkBox => handleCustomCheckbox(checkBox));
    document.addEventListener('change', function(e){
      if(e.target.type === "checkbox"){
        handleCustomCheckbox(e.target);
      }else if(e.target.type === "file"){
        let link = e.target.parentNode.nextElementSibling;
        if(e.target.value !== ""){
          link.children[0].setAttribute("src", "src/images/svg/pdf.svg");
        }
      }
    })
    document.querySelector(".filter-wrapper");
  }else if(document.getElementById('patient-record-form')){
    document.querySelectorAll(`input[type="checkbox"]`).forEach(checkBox => handleCustomCheckbox(checkBox));
    document.getElementById("patient-record-form").addEventListener('change', function(e){
      if(e.target.type === "checkbox"){
        handleCustomCheckbox(e.target);
      }
    })
  }else{
    let checkboxes = document.querySelectorAll(`input[type="checkbox"]`);
    checkboxes.forEach(checkbox => checkbox.addEventListener("change", function(e){
      handleCustomCheckbox(e.target);
    }))
  }

  function handleCustomCheckbox(input){
      let label = input.parentNode;
      if(input.checked){
        label.classList.add("checked");
      }else{
        label.classList.remove("checked");
      }
  }


  // Validation
 
  if(document.getElementById("create-user-form")){
    const forms = document.querySelectorAll('.form-sec');
    forms.forEach(form => handleFormSec(form, true));
  }else if(document.querySelector('.form-sec')){
    const forms = document.querySelectorAll('.form-sec');
    forms.forEach(form => handleFormSec(form));
  }

  function handleFormSec(formSec, inline=false){
    const inputsToBeValidated = ["username", "password", "inlineFormInputGroupUsername", "firstname", "lastname", "profile-email", "old_password", "new_password", "new_password_re"]
    formSec.setAttribute('novalidate', true);

    formSec.addEventListener('submit', (e) => {

      const inputs = e.target.querySelectorAll('input');
      inputs.forEach(input => {
        if(inputsToBeValidated.includes(input.id)){
          if(!input.validity.valid){
            e.preventDefault()
            if(inline){
              validateField(input, true)
            }else{
              validateField(input)
            }
          }else{
            showMessage(input,"")
          }
        }
      })
      const ids = Array.from(inputs).map( input => input.id);
      //console.log(ids);

      if(ids.includes("new_password") && ids.includes("new_password_re")){
        if(formSec.new_password.value !== formSec.new_password_re.value){
          if(inline)
            showMessage(formSec.new_password_re, "Passwords do not match", {inlineForm: true})
          else
            showMessage(formSec.new_password_re, "Passwords do not match")
        }
      }
    })
  }

  if(document.getElementById("create-user-form")){
    // TODO finish this
    const forms = Array.from(document.forms);
    forms.forEach(form => {
      form.setAttribute("novalidate", true);
      form.addEventListener("submit", function(e){
      e.preventDefault();
      
      //console.log(e.target)
      })
    })
  }

  const searchForm = document.querySelector('form.search-filter');
  if(searchForm){
    const searchInputs = searchForm.querySelectorAll('input');
    const submitButton = searchForm.querySelector('button[type="submit"]');
    const dateRegex = /^(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
    submitButton.disabled = true;
    searchInputs.forEach((input) => {
      input.addEventListener('blur', (e) => { searchValidation(input)});
      input.addEventListener('keyup', (e) => { searchValidation(input) });
    })

    function searchValidation(input){
      setTimeout( () => {
        var searchValues = Array.from(searchInputs, inputField => inputField.value)
        var dateInput = searchForm.querySelector("#date-input");
        if( dateInput && dateInput.value !== "" && !dateRegex.test(dateInput.value)){
          submitButton.disabled = true;
          showMessage(dateInput, "Please enter a valid date in YYYY-MM-DD format")
        }
        else if( searchValues.every( val => val === "")){
          submitButton.disabled = true;
          hideMessage(input);
        }
        else if( searchValues.some( val => val.length >= 3)){
          submitButton.disabled = false;
          if(input.id !== "date-picker"){
            searchInputs.forEach(input => hideMessage(input))
          }
        }else{
          submitButton.disabled = true;
          showMessage(input, "Please enter at least 3 characters", {inlineForm: true})
        }
      }, 200)
      
    }
  }
  
  const passChangeForm = document.querySelector('.pass-change-form');
  if(passChangeForm){
    const inputsToBeValidated = ["new_pass", "new_pass_repeat"]
    passChangeForm.setAttribute('novalidate', true);
    passChangeForm.addEventListener('submit', (e) => {
      const inputs = e.target.querySelectorAll('input');
      inputs.forEach(input => {
        if(inputsToBeValidated.includes(input.id)){
          if(!input.validity.valid){
            e.preventDefault()
            validateField(input)
          }else{
            hideMessage(input)
          }
        }
      })
      if(inputs[0].value !== inputs[1].value){
        showMessage(inputs[1], "Passwords do not match");
      }else{
        showMessage(inputs[1], "Passwords match");
      }
    })
  }

  const activityLogForm = document.getElementById('activity-log-form');
  if(activityLogForm){
    const dateRegex = /^(19|20)\d\d-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
    const inputsToBeValidated = ["start-date-input", "end-date-input"]
    activityLogForm.setAttribute('novalidate', true);

    activityLogForm.addEventListener('submit', (e) => {
      const inputs = activityLogForm.querySelectorAll('input');
      inputs.forEach(input => {
        if(inputsToBeValidated.includes(input.id)){
          if(input.validity.valueMissing){
            e.preventDefault()
            showMessage(input, "Please enter a date");
          }else if(!dateRegex.test(input.value)){
            e.preventDefault()
            showMessage(input, "Please enter a valid date in YYYY-MM-DD format.");
          }else{
            hideMessage(input);
          }
        }
      })
      if(formSec.id === "editUserForm"){
        if(formSec.new_password.value !== formSec.new_password_re.value){
          showMessage(formSec.new_password_re, "Passwords do not match")
        }
      }
    })
  }

 
  if(document.querySelector('.plusminus')){
    const expanders = document.querySelectorAll('.help-header');
    expanders.forEach((plusminus) => {
      plusminus.addEventListener('click', function(e){
        var text = plusminus.nextElementSibling;
        var img = plusminus.children[1].children[0];
        if(!text.classList.contains("active")){
          text.classList.add("active");
          img.setAttribute("src", "src/images/svg/remove.svg");
        }else{
          text.classList.remove("active");
          img.setAttribute("src", "src/images/svg/add.svg");
        }
      })
    })
  }

});



$(".filter_invoice_sent .record-check").on("change",function(){
  if ($(this).is(':checked')){
    $('.record-table table tr').not(".propt-th").filter(function() {
      $(this).toggle($(this).find('input[name="invoice_sent"]')[0].checked);
    });
  }
  else if (!$(this).is(':checked')){
    $('.record-table table tr').show();
  }
  
});

$(".filter_payment_received .record-check").on("change",function(){
  if ($(this).is(':checked')){
    $('.record-table table tr').not(".propt-th").filter(function() {
      $(this).toggle($(this).find('input[name="payment_received"]')[0].checked);
    });
  }
  else if (!$(this).is(':checked')){
    $('.record-table table tr').show();
  }
  
});

$(".filter_records_sent .record-check").on("change",function(){
  if ($(this).is(':checked')){
    $('.record-table table tr').not(".propt-th").filter(function() {
      $(this).toggle($(this).find('input[name="records_sent"]')[0].checked);
    });
  }
  else if (!$(this).is(':checked')){
    $('.record-table table tr').show();
  }
  
});

function pad2(number) {
   
  return (number < 10 ? '0' : '') + number;

}

function validateField(field, inline=false){
    let message = "";
    if(field.type === "email"){
      if(field.validity.valueMissing){
        message = "Please enter an email";
      }else if(field.validity.patternMismatch){
        message = "Please enter a valid email address";
      }
    }else if(field.type === "password"){
      if(field.validity.valueMissing){
         message = "Please enter a password";
      }
    }else if(field.type === "text"){
      if(field.validity.valueMissing){
        message = "Please enter this field";
      }
    }
    if(inline){
      showMessage(field, message, {inlineForm: true});
    }else{
      showMessage(field, message);
    }
    
}

function showMessage(field, error, {inlineForm = false} = {}){
  const id = field.id;
  var message = field.form.querySelector('.error-message#error-for-' + id );
  if (!message) {
      message = document.createElement('div');
      message.className = 'error-message';
      message.id = 'error-for-' + id;
      if(!inlineForm){
        field.parentNode.insertAdjacentElement( "afterend",message );
      }else{
        field.insertAdjacentElement( "afterend",message );
      }
  }
  message.classList.remove('hide')
  message.classList.add('show');
  message.innerHTML = error;
  message.style.display = 'block';
  message.style.visibility = 'visible';
}



function hideMessage(field){
  const id = field.id;
  var message = field.form.querySelector('.error-message#error-for-' + id );
  if(!message) return;
  message.classList.remove('show')
  message.classList.add('hide');
  message.innerHTML = "";
}



// blockUI();
// blockUI();
// setTimeout( unblockUI, 3000);

