import 'bootstrap/dist/css/bootstrap.css';
import '../styles/index.scss';
import '../scripts/jquery';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../scripts/datatables';
import '../scripts/dataTables.bootstrap4';
import '../scripts/jquery.nicescroll';
import '../scripts/datepicker';
import '../scripts/custom';
console.log('webpack starterkit');
